<script setup lang="ts">
  import { MpIcon, pushToastMessage } from '@borg/ui';
  import { JobDetails, JobPreview, JobSimple } from '@borg/types';
  import { isUndefined } from '@borg/utils';
  import { openAuthFormModal } from '@/components/Auth/AuthFormModal.vue';
  import SaveJobSuccessToastSubtitle from '@/components/SaveJobSuccessToastSubtitle.vue';
  import { ifAuth } from '@/utils/user';

  const props = defineProps<{
    job: JobPreview | JobSimple | JobDetails;
  }>();

  const { t } = useI18n();
  const userInfo = useUserInfo();
  const isJobUserInfoSavedSaved = ref(props.job.userInfo.isSaved);
  const isSaved = computed(() => {
    const userInfoValue = userInfo.getSaved(props.job.id);
    return !isUndefined(userInfoValue) ? userInfoValue : isJobUserInfoSavedSaved.value;
  });
  const iconName = computed(() => (isSaved.value ? 'bookmark_fill' : 'bookmark'));

  async function save() {
    if (isSaved.value) {
      isJobUserInfoSavedSaved.value = false;
      userInfo.setSaved(props.job.id, false);
      const result = await myJobsService.undoJobSave(props.job.id);
      if (!result.isSuccess) {
        isJobUserInfoSavedSaved.value = true;
        userInfo.setSaved(props.job.id, true);
        pushToastMessage({
          type: 'alert',
          title: t('JOB_CARD.UNDO_SAVE_JOB_ERROR_TOAST.TITLE'),
          subtitle: t('JOB_CARD.UNDO_SAVE_JOB_ERROR_TOAST.SUBTITLE'),
        });
      }
    } else {
      isJobUserInfoSavedSaved.value = true;
      userInfo.setSaved(props.job.id, true);
      const result = await myJobsService.saveJob(props.job.id);
      if (!result.isSuccess) {
        isJobUserInfoSavedSaved.value = false;
        userInfo.setSaved(props.job.id, false);
        pushToastMessage({
          type: 'alert',
          title: t('JOB_CARD.SAVE_JOB_ERROR_TOAST.TITLE'),
          subtitle: t('JOB_CARD.SAVE_JOB_ERROR_TOAST.SUBTITLE'),
        });
      } else {
        pushToastMessage({
          type: 'success',
          title: t('JOB_CARD.SAVE_JOB_SUCCESS_TOAST.TITLE'),
          subtitle: h(SaveJobSuccessToastSubtitle),
        });
      }
    }
  }
</script>

<template>
  <div
    data-test="job-save-button no-print"
    :class="{ 'job-save-button': true, 'job-save-button--saved': isSaved }"
  >
    <MpIcon
      transition="scale"
      :icon="iconName"
      @click="ifAuth(save, () => openAuthFormModal({ showLogo: false }))"
    />
  </div>
</template>

<style scoped lang="scss">
  .job-save-button {
    color: var(--mp-color-text-40);
    cursor: pointer;
    user-select: none;
    transition: color 150ms;

    &--saved {
      color: var(--mp-color-system-a-dark);
    }

    @include dark-mode {
      color: var(--mp-color-text-05);

      &--saved {
        color: var(--mp-color-system-a-dark);
      }
    }
  }
</style>
